import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import ghanaRegions from "../../data/ghanaRegions";
import { resetSuccessFlag } from "../../features/school/schoolSlice";
import { AdminDetails } from "../../features/admin/types";
import { updateAdmin } from "../../features/admin/adminSlice";
import { adminDataSchema } from "../../schemas/index";
import { toast } from "react-toastify";
import * as Yup from "yup";
interface UpdateAdminPopupProps {
  open: boolean;
  onClose: () => void;
  admin: AdminDetails;
  onSave: (school: AdminDetails) => void;
}
interface Region {
  name: string;
  districts: string[];
}

const UpdateAdminPopup: React.FC<UpdateAdminPopupProps> = ({
  open,
  onClose,
  admin,
  onSave,
}) => {
  const [editedAdmin, setEditedAdmin] = useState<AdminDetails>({
    createdby: "undefined",
    _id: "undefined",
    createdAt: "undefined",
    updatedAt: "undefined",
    __v: undefined,
    name: "apex international school",
    email: "wirelessbhai@gmail.com",
    phone: "+918626808149",
    address: "shimla",
    distt: "",
    state: "",
    role: "",
  });
  useEffect(() => {
    setEditedAdmin(admin);
  }, [admin]);

  const dispatch: AppDispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.auth.profile);

  const loading = useSelector((state: RootState) => state.school.loading);
  const success = useSelector((state: RootState) => state.school.success);
  const [districtOptions, setDistrictOptions] = useState<string[]>([]);
  const [stateOptions, setStateOptions] = useState<Region[]>(ghanaRegions);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    if (userData?.role === "region") {
      const userRegion = ghanaRegions.find(
        (region) => region.name === userData.state
      );
      if (userRegion) {
        setDistrictOptions(userRegion.districts);
      }
    } else if (userData?.role === "distt") {
      setEditedAdmin((prevData) => ({
        ...prevData,
        state: userData.state,
        distt: userData.distt,
      }));
    } else if (userData?.role === "national") {
      setDistrictOptions([]);
    }
  }, [userData]);

  useEffect(() => {
    if (success) {
      dispatch(resetSuccessFlag());
      onClose();
    }
  }, [success, dispatch, onClose]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setValidationErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
    setEditedAdmin((prev) => ({ ...prev, [id]: value }));

    if (id === "state") {
      const selectedRegion = ghanaRegions.find(
        (region) => region.name === value
      );
      setDistrictOptions(selectedRegion ? selectedRegion.districts : []);
      setEditedAdmin((prevData) => ({ ...prevData, distt: "" }));
    }
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await adminDataSchema.validate(editedAdmin, { abortEarly: false });
      onSave(editedAdmin);
      toast.success("Admin details saved successfully!");
      onClose();
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        const newValidationErrors: Record<string, string> = {};
        errors.inner.forEach((error) => {
          newValidationErrors[error.path as string] = error.message;
        });
        setValidationErrors(newValidationErrors);
      } else {
        console.error("An  unexpected error occured:", errors);
        toast.error("Failed to save admin details. Please try again.");
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onOk={onClose}
        onCancel={onClose}
        footer={null}
        className="shadow-xl  xl:text-25px"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "75%",
        }}
      >
        <form onSubmit={handleSave} className="xl:w-[1100px] lg:text-[14px] ">
          <h1 className="lg:text-[18px] font-semibold mb-10">
            Update New Admin
          </h1>
          <div className="grid grid-cols-2 gap-6 2xl:gap-10  ">
            <div>
              <label className="lg:text-[14px] font-semibold ">
                {" "}
                Name <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full my-2  bg-white border border-gray-300 pl-3   inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="name"
                value={editedAdmin.name}
                onChange={handleChange}
              />
              {validationErrors.name && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.name}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold ">
                {" "}
                Address <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full  py-2 my-2  bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="address"
                value={editedAdmin.address}
                onChange={handleChange}
              />
              {validationErrors.address && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.address}
                </div>
              )}
              {userData?.role === "national" && (
                <div>
                  <label className="lg:text-[14px] font-semibold">
                    Regions <span className="text-pink-800">*</span>
                  </label>
                  <select
                    className="outline-none rounded-lg w-full py-2 mt-3 mb-3 bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] xl:h-[50px]"
                    id="state"
                    value={editedAdmin.state}
                    onChange={handleChange}
                  >
                    <option value=""> Select new state</option>
                    {stateOptions.map((region) => (
                      <option key={region.name} value={region.name}>
                        {region.name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.state && (
                    <div className="text-red-500 text-[12px]">
                      {validationErrors.state}
                    </div>
                  )}
                </div>
              )}

              <label className="lg:text-[14px] font-semibold">
                Role <span className="text-pink-800">*</span>
              </label>

              <select
                className="outline-none rounded-lg w-full py-2 my-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] xl:h-[50px]"
                id="role"
                value={editedAdmin.role}
                onChange={handleChange}
              >
                <option>Select Role</option>
                {userData?.role === "national" && (
                  <option key={"national"} value={"national"}>
                    National Admin
                  </option>
                )}
                {userData?.role === "national" && (
                  <option key={"region"} value={"region"}>
                    Regional Admin
                  </option>
                )}
                {userData?.role === "national" && (
                  <option key={"distt"} value={"distt"}>
                    District Admin
                  </option>
                )}
                {userData?.role === "region" && (
                  <option value={"region"}>Regional Admin</option>
                )}
                {userData?.role === "region" && (
                  <option value={"distt"}>District Admin</option>
                )}
                {userData?.role === "distt" && (
                  <option key={"national"} value={"distt"}>
                    District Admin
                  </option>
                )}
              </select>
              {validationErrors.role && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.role}
                </div>
              )}
            </div>
            <div>
              <label className="lg:text-[14px] font-semibold">
                Email <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full my-2 py-2   bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="email"
                value={editedAdmin.email}
                onChange={handleChange}
              />
              {validationErrors.email && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.email}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold">
                Phone <span className="text-pink-800">*</span>
              </label>
              <p className="text-[12px] lg:text-[12px] text-gray-500 ">
                {/* Should be a valid phone number */}
              </p>

              <input
                className=" outline-none  rounded-lg w-full  my-2 py-2   bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="phone"
                value={editedAdmin.phone}
                onChange={handleChange}
              />
              {validationErrors.phone && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.phone}
                </div>
              )}
              {(userData?.role === "national" ||
                userData?.role === "region") && (
                <div>
                  <label className="lg:text-[14px] font-semibold">
                    District <span className="text-pink-800">*</span>
                  </label>
                  <select
                    className="outline-none rounded-lg w-full py-2 bg-white border mt-3 mb-3 border-gray-300 pl-3 inline-block box-border h-[35px] xl:h-[50px]"
                    id="distt"
                    value={editedAdmin.distt}
                    onChange={handleChange}
                  >
                    <option value=""> Select your district</option>
                    {districtOptions.map((district, index) => (
                      <option key={index} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                  {validationErrors.distt && (
                    <div className="text-red-500 text-[12px]">
                      {validationErrors.distt}
                    </div>
                  )}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="rounded-lg bg-black text-[15px] xl:text-[18px] text-white mt-8 w-[130px] px-3 py-3"
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UpdateAdminPopup;
