import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal } from "antd";
import { SchoolFormData } from "../features/school/types";
import { useDispatch, useSelector } from "react-redux";
import { createSchool, resetSuccessFlag } from "../features/school/schoolSlice";
import { AppDispatch, RootState } from "../store";
import ghanaRegions from "../data/ghanaRegions";
import { toast } from "react-toastify";

import { schoolDataSchema } from "../schemas/index";
import * as Yup from "yup";

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Region {
  name: string;
  districts: string[];
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose }) => {
  const dispatch: AppDispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.auth.profile);
  const [schoolData, setSchoolData] = useState<SchoolFormData>({
    name: "",
    username: "",
    email: "",
    phone: "",
    gatewayIp: "",
    routerIp: "",
    address: "",
    distt: "",
    state: "",
  });

  const loading = useSelector((state: RootState) => state.school.loading);
  const success = useSelector((state: RootState) => state.school.success);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [districtOptions, setDistrictOptions] = useState<string[]>([]);
  const [stateOptions, setStateOptions] = useState<Region[]>([]);

  useEffect(() => {
    setStateOptions(ghanaRegions);

    if (userData?.role === "region") {
      const userRegion = ghanaRegions.find(
        (region) => region.name === userData.state
      );

      if (userRegion) {
        setSchoolData((prevData) => ({ ...prevData, state: userData.state }));
        setDistrictOptions(userRegion.districts);
      } else {
        console.log(`No region found matching the state: ${userData.state}`);
      }
    } else if (userData?.role === "distt") {
      const userRegion = ghanaRegions.find(
        (region) => region.name === userData.state
      );

      if (userRegion) {
        setSchoolData((prevData) => ({
          ...prevData,
          state: userData.state,
          distt: userData.distt,
        }));
        setDistrictOptions(userRegion.districts);
      } else {
        console.log(`No region found matching the state: ${userData.state}`);
      }
    } else if (userData?.role === "national") {
      setDistrictOptions([]);
      setSchoolData((prevData) => ({ ...prevData, state: "", distt: "" }));
    }
  }, [userData]);

  useEffect(() => {
    if (success) {
      setSchoolData({
        name: "",
        username: "",
        email: "",
        phone: "",
        gatewayIp: "",
        routerIp: "",
        address: "",

        distt: "",
        state: "",
      });
      dispatch(resetSuccessFlag());
      toast("School Created");
      onClose();
    }
  }, [success, dispatch, onClose]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setValidationErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
    setSchoolData((prevData) => ({ ...prevData, [id]: value }));

    if (id === "state" && userData?.role === "national") {
      const selectedRegion = ghanaRegions.find(
        (region) => region.name === value
      );
      setDistrictOptions(selectedRegion ? selectedRegion.districts : []);
      setSchoolData((prevData) => ({ ...prevData, distt: "" }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log("from handleschool sumbit");
      await schoolDataSchema.validate(schoolData, { abortEarly: false });
      dispatch(createSchool(schoolData));
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        const newValidationErrors: Record<string, string> = {};
        errors.inner.forEach((error) => {
          newValidationErrors[error.path as string] = error.message;
        });
        setValidationErrors(newValidationErrors);
      } else {
        console.error("An  unexpected error occured:", errors);
      }
    }
  };

  return (
    <>
      <Modal
        title=""
        open={isOpen}
        onCancel={onClose}
        className="shadow-xl"
        width="75%"
        footer={null}
      >
        <form onSubmit={handleSubmit} className="xl:w-[1100px]!">
          <h1 className="lg:text-[18px] font-semibold mb-5">
            Create New School
          </h1>
          <div className="grid grid-cols-2 gap-6  ">
            <div>
              <label className="lg:text-[14px] font-semibold">
                Name <span className="text-pink-800">*</span>
              </label>
              <input
                className="outline-none rounded-lg w-full my-2 py-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] lg:h-[50px]"
                type="text"
                id="name"
                value={schoolData.name}
                onChange={handleChange}
              />
              {validationErrors.name && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.name}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold">
                Username <span className="text-pink-800">*</span>
              </label>
              <p className="text-[12px] text-gray-500 mb-2 lg:text-[12px]">
                Should be unique to each school
              </p>
              <input
                className="outline-none my-2 rounded-lg w-full py-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] lg:h-[50px]"
                type="text"
                id="username"
                value={schoolData.username}
                onChange={handleChange}
              />
              {validationErrors.username && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.username}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold">
                Gateway <span className="text-pink-800">*</span>
              </label>
              <p className="text-[12px] text-gray-500 mb-2 lg:text-[12px]">
                Should be unique to each school
              </p>
              <input
                className="outline-none rounded-lg w-full my-2 py-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] lg:h-[50px]"
                type="text"
                id="gatewayIp"
                value={schoolData.gatewayIp}
                onChange={handleChange}
              />
              {validationErrors.gatewayIp && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.gatewayIp}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold">
                Address <span className="text-pink-800">*</span>
              </label>
              <input
                className="outline-none rounded-lg w-full my-2 py-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] lg:h-[50px]"
                type="text"
                id="address"
                value={schoolData.address}
                onChange={handleChange}
              />
              {validationErrors.address && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.address}
                </div>
              )}
              {userData?.role === "national" && (
                <div>
                  <label className="lg:text-[14px] font-semibold">
                    Regions <span className="text-pink-800">*</span>
                  </label>
                  <select
                    className="outline-none rounded-lg w-full  my-2 py-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] lg:h-[50px]"
                    id="state"
                    value={schoolData.state}
                    onChange={handleChange}
                  >
                    <option value=""> Select state</option>
                    {stateOptions.map((region) => (
                      <option key={region.name} value={region.name}>
                        {region.name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.state && (
                    <div className="text-red-500 text-[12px]">
                      {validationErrors.state}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div>
              <label className="lg:text-[14px] font-semibold">
                Email <span className="text-pink-800">*</span>
              </label>
              <input
                className="outline-none rounded-lg w-full  my-2 py-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] lg:h-[50px]"
                type="text"
                id="email"
                value={schoolData.email}
                onChange={handleChange}
              />
              {validationErrors.email && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.email}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold">
                Router <span className="text-pink-800">*</span>
              </label>
              <p className="text-[12px] text-gray-500 mb-2 lg:text-[12px]">
                Should be unique to each school
              </p>
              <input
                className="outline-none rounded-lg w-full my-2 py-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] lg:h-[50px]"
                type="text"
                id="routerIp"
                value={schoolData.routerIp}
                onChange={handleChange}
              />
              {validationErrors.routerIp && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.routerIp}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold">
                Phone <span className="text-pink-800">*</span>
              </label>
              <p className="text-[12px] text-gray-500 mb-2 lg:text-[12px]">
                Should be a valid phone number
              </p>
              <input
                className="outline-none rounded-lg w-full py-2  my-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] lg:h-[50px]"
                type="text"
                id="phone"
                value={schoolData.phone}
                onChange={handleChange}
              />
              {validationErrors.phone && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.phone}
                </div>
              )}
              {/* <label className="lg:text-[14px] font-semibold">
                City <span className="text-pink-800">*</span>
              </label>
              <input
                className="outline-none rounded-lg w-full py-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]"
                type="text"
                id="city"
                value={schoolData.city}
                onChange={handleChange}
              /> */}
              {(userData?.role === "national" ||
                userData?.role === "region") && (
                <div>
                  <label className="lg:text-[14px] font-semibold">
                    District <span className="text-pink-800">*</span>
                  </label>
                  <select
                    className="outline-none rounded-lg w-full py-2  my-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] lg:h-[50px]"
                    id="distt"
                    value={schoolData.distt}
                    onChange={handleChange}
                  >
                    <option value=""> Select district</option>
                    {districtOptions.map((district, index) => (
                      <option key={index} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>

                  {validationErrors.distt && (
                    <div className="text-red-500 text-[12px]">
                      {validationErrors.distt}
                    </div>
                  )}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="rounded-lg bg-black  lg:text-[14px] text-[15px] text-white mt-8 w-[130px] px-3 py-3"
            >
              {loading ? "Creating..." : "Create"}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Popup;
