import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/index";
import { setCredentials } from "../features/auth/authSlice";

export const store = configureStore({
  reducer: rootReducer,
});

const authToken = localStorage.getItem("authToken");
if (authToken) {
  // store.dispatch(setLoginError("Invalid credentials"));
  store.dispatch(setCredentials({ token: authToken }));
}
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
