import React from "react";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store/index";
// import store from "./reducers/Store
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
} else {
  console.error("Failed to find the root element");
}
