import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { SchoolState, resetPassword } from "../../features/school/schoolSlice";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

export const ResetSchoolPassword = () => {
  const { token } = useParams<{ token?: string }>();
  const [newPassword, setNewPassword] = useState("");
  const dispatch: AppDispatch = useDispatch();
  const { loading, error, success } = useSelector(
    (state: RootState) => state.school as SchoolState
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (token) {
      dispatch(resetPassword({ token, newPassword }));
    } else {
      console.error("Token is undefined");
    }
    if (success) {
    }
  };
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success === true) {
      toast.success("Password reset email sent successfully!");
    }
  }, [loading, error, success]);

  if (!token) {
    return <Navigate to="/error-page" />; // Redirect to an error page
  }
  return (
    <div className="relative md:w-4/12 mx-auto mt-24 flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 rounded-2xl bg-clip-border">
      <div className="p-6 pb-0 mb-0">
        <h4 className="font-bold text-2xl text-[#344767]">New Password</h4>
        <p className="mb-0 mt-2 text-[#344767c2]">Enter Your new Password</p>
      </div>
      <div className="flex-auto p-6">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="password"
              id="password"
              placeholder="Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
            />
            <div
              data-lastpass-icon-root="true"
              style={{
                position: "relative",
                height: "0px",
                width: "0px",
                float: "left",
              }}
            ></div>{" "}
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="inline-block w-full px-16 py-3.5 mt-4 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-violet-500 border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25"
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                "Reset Password"
              )}
            </button>
          </div>
        </form>
        {error && <p className="text-red-500 mt-5">{error}</p>}

        {success && (
          <p className="text-green-500">Password successfully reset.</p>
        )}
      </div>
    </div>
  );
};
