import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchUserProfile, login } from "../../api/authAPI";
import { Credentials } from "../admin/types";
import { loadavg } from "os";

interface AuthState {
  token: string | null;
  profile: any | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
  loginError: string | null;
  loading: boolean;
}

const initialState: AuthState = {
  token: null,
  profile: null,
  status: "idle",
  error: null,
  loginError: null,
  loading: false,
};
export const loginUser = createAsyncThunk(
  "auth/login",
  async (credentials: Credentials, { rejectWithValue }) => {
    try {
      const data = await login(credentials);
      return data.token;
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("");
    }
  }
);

export const fetchProfile = createAsyncThunk(
  "auth/fetchProfile",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.auth.token;

    if (!token) {
      return rejectWithValue("No token found");
    }
    try {
      const profileData = await fetchUserProfile(token);
      return profileData;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch profile");
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      localStorage.removeItem("authToken");
    },
    setLoginError: (state, action) => {
      state.loginError = action.payload;
    },
    setCredentials: (state, action) => {
      state.token = action.payload.token;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading = false;
        state.token = action.payload;
        localStorage.setItem("authToken", action.payload);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.status = "idle";
        state.profile = action.payload;
        localStorage.setItem("userProfile", JSON.stringify(action.payload));
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});
export const { logout, setCredentials, setLoginError } = authSlice.actions;
export default authSlice.reducer;
