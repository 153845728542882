import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  addSchool,
  deleteSchoolApi,
  fetchICMPPingHistoryApi,
  fetchSchoolsApi,
  forgotPasswordApi,
  resetPasswordApi,
  updateSchoolApi,
} from "../../api/authAPI";
import { SchoolData, SchoolDetails } from "./types";

export interface ICMPHistoryEntry {
  itemid: string;
  clock: string;
  value: string;
  ns: string;
}

export interface SchoolState {
  schools: SchoolDetails[];
  loading: boolean;
  error: string | null;
  success: boolean | null;
  gatewayHistory: ICMPHistoryEntry[] | null;
  routerHistory: ICMPHistoryEntry[] | null;
}
const initialState: SchoolState = {
  schools: [],
  loading: false,
  error: null,
  success: false,
  gatewayHistory: null,
  routerHistory: null,
};

interface UpdateSchoolPayload {
  schoolId: string;
  schoolData: SchoolDetails;
}
interface FetchICMPPingHistoryArgs {
  schoolId: string;
  timeFrom: number;
  timeTill: number;
}
export const fetchICMPPingHistory = createAsyncThunk(
  "school/fetchICMPPingHistory",
  async (
    { schoolId, timeFrom, timeTill }: FetchICMPPingHistoryArgs,
    { getState, rejectWithValue }
  ) => {
    try {
      const token = (getState() as RootState).auth.token;
      if (!token) {
        return rejectWithValue("Authentication token not found");
      }
      const response = await fetchICMPPingHistoryApi(
        schoolId,
        timeFrom,
        timeTill,
        token
      );
      const { gatewayHistory, routerHistory } = response;
      return { gatewayHistory, routerHistory };
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Error fetching ICMP Ping history"
      );
    }
  }
);

export const fetchSchools = createAsyncThunk(
  "schools/fetchSchools",
  async (_, { getState, rejectWithValue }) => {
    const token = (getState() as RootState).auth.token;
    if (!token) {
      return rejectWithValue("Authentication token not found");
    }
    try {
      const data = await fetchSchoolsApi(token);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "Error fetching schools");
    }
  }
);

export const createSchool = createAsyncThunk(
  "school/createSchool",
  async (schoolData: SchoolData, { getState, rejectWithValue }) => {
    console.log(schoolData);
    const token = (getState() as RootState).auth.token;
    if (!token) {
      return rejectWithValue("Authentication token not found");
    }

    try {
      const response = await addSchool(schoolData, token);
      return response;
    } catch (error: any) {
      if (error.response?.status === 409) {
        // HTTP status 409 (Conflict) indicates that the email already exists
        return rejectWithValue("Email already exists");
      }
      return rejectWithValue(
        error.response?.data?.message || "Error adding school"
      );
    }
  }
);

export const updateSchool = createAsyncThunk(
  "schools/updateSchool",
  async (
    { schoolId, schoolData }: UpdateSchoolPayload,
    { getState, rejectWithValue }
  ) => {
    try {
      const token = (getState() as RootState).auth.token;
      if (!token) {
        throw new Error("Authentication token not found");
      }

      // Create a sanitized update object, excluding the password
      const { password, ...sanitizedUpdateData } = schoolData;

      const response = await updateSchoolApi(
        schoolId,
        sanitizedUpdateData,
        token
      );
      return { schoolId, data: response };
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Error updating school"
      );
    }
  }
);

export const deleteSchool = createAsyncThunk(
  "schools/deleteSchool",
  async (schoolId: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const token = state.auth.token;
      if (!token) {
        return rejectWithValue("Authentication token not found");
      }
      await deleteSchoolApi(schoolId, token);
      return schoolId;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Error deleting school"
      );
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "admin/forgot-password",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await forgotPasswordApi(email);
      return response.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "admin/reset-password",
  async (data: { token: string; newPassword: string }, { rejectWithValue }) => {
    try {
      const response = await resetPasswordApi(data.token, data.newPassword);
      return response.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {
    resetSuccessFlag: (state) => {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSchool.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSchool.fulfilled, (state, action) => {
        state.loading = false;
        state.schools.push(action.payload.userDetails);
        state.success = true;
      })
      .addCase(createSchool.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Unknown error occurred";
      })
      .addCase(fetchSchools.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchSchools.fulfilled, (state, action) => {
        state.loading = false;
        state.schools = action.payload;
      })
      .addCase(fetchSchools.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSchool.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        const index = state.schools.findIndex(
          (school) => school._id === action.payload.schoolId
        );
        if (index !== -1) {
          state.schools[index] = {
            ...state.schools[index],
            ...action.payload.data,
          };
        }
      })
      .addCase(deleteSchool.fulfilled, (state, action) => {
        state.schools = state.schools.filter(
          (school) => school._id !== action.payload
        );
        state.success = true;
      })
      .addCase(deleteSchool.rejected, (state, action) => {
        state.error =
          action.error.message || "Unknown error occurred during deletion";
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload as string;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload as string;
      })
      .addCase(fetchICMPPingHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchICMPPingHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.gatewayHistory = action.payload.gatewayHistory;
        state.routerHistory = action.payload.routerHistory;
      })
      .addCase(fetchICMPPingHistory.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch ICMP Ping history";
      });
  },
});
export const { resetSuccessFlag } = schoolSlice.actions;

export default schoolSlice.reducer;
