import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../store";
import { RiRadioButtonLine } from "react-icons/ri";
import { SchoolDetails } from "../../features/school/types";
import { deleteSchool } from "../../features/school/schoolSlice";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../../src/index.css";
import CustomDropdownSchool from "../CustomDropdownSchool";
import { useNavigate } from "react-router-dom";
interface SchoolsTableProps {
  onEdit: (school: SchoolDetails) => void;
}

const SchoolsTable: React.FC<SchoolsTableProps> = ({ onEdit }) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedSchoolId, setSelectedSchoolId] = useState("");
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleConfirmDelete = () => {
    if (selectedSchoolId) {
      dispatch(deleteSchool(selectedSchoolId));
    }
    setConfirmDialogOpen(false);
  };

  const schools = useSelector((state: RootState) => state.school.schools);
  const handleView = (schoolId: string) => {
    navigate(`/schools/${schoolId}/graph`); // Navigate to the graph page
  };
  const dispatch = useDispatch<AppDispatch>();
  const renderStatus = (status: any) => {
    return status ? (
      <RiRadioButtonLine style={{ color: "green" }} />
    ) : (
      <RiRadioButtonLine style={{ color: "red" }} />
    );
  };
  const handleToggleDropdown = (adminId: string) => {
    setOpenDropdownId(openDropdownId === adminId ? null : adminId);
  };
  const columns = [
    {
      name: "name",
      label: "School Name",
    },
    // { name: "username", label: "Username" },
    { name: "email", label: "Email" },
    { name: "phone", label: "Phone" },
    { name: "address", label: "Address" },
    // { name: "city", label: "City" },
    { name: "distt", label: "District" },
    { name: "state", label: "State" },
    // { name: "gatewayIp", label: "Gateway IP" },
    // { name: "routerIp", label: "Router IP" },
    // { name: "gatewayId", label: "Gateway ID" },
    // { name: "routerId", label: "Router ID" },
    {
      name: "gatewayStatus",
      label: "Gateway Status",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return renderStatus(schools[dataIndex]?.gatewayStatus);
        },
      },
    },
    {
      name: "routerStatus",
      label: "Router Status",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return renderStatus(schools[dataIndex]?.routerStatus);
        },
      },
    },
    {
      name: "isOnline",
      label: "Online Status",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return renderStatus(schools[dataIndex]?.isOnline);
        },
      },
    },

    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: any) => {
          const school = schools[dataIndex];

          return (
            <>
              <CustomDropdownSchool
                school={school}
                isOpen={openDropdownId === school?._id}
                onToggle={() => handleToggleDropdown(school?._id!)}
                onEdit={() => onEdit(school)}
                onDelete={() => handleDelete(school?._id!)}
                onView={handleView}
              />
              {/* <IconButton onClick={() => handleView(school)}>
                <VisibilityIcon />
              </IconButton>
              <IconButton onClick={() => onEdit(school)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(school._id!)}>
                <DeleteIcon />
              </IconButton> */}
            </>
          );
        },
        classes: {
          root: "table-container",
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox" as const,
    responsive: "standard" as const,
    download: true,
    print: true,
    selectableRows: "none" as const,
    elevation: 0,
  };

  const handleDelete = (schoolId: string) => {
    setSelectedSchoolId(schoolId);
    setConfirmDialogOpen(true);
  };
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              backgroundColor: "",
              fontsize: "20px",
            },
          },
        },

        MuiToolbar: {
          styleOverrides: {
            regular: {},
          },
        },
      },
    });

  return (
    <>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"School List"}
          data={schools}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete this school?"
      />
    </>
  );
};

export default SchoolsTable;
