import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AdminDetails } from "../../features/admin/types";
import { AppDispatch } from "../../store";
import AdminTable from "../../components/Tables/AdminTable";
import UpdateAdminPopup from "./UpdateAdminPopup";
import { updateAdmin } from "../../features/admin/adminSlice";

const Admin = () => {
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState<AdminDetails | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  const handleEdit = (school: any) => {
    setCurrentAdmin(school);
    setEditPopupOpen(true);
  };
  const handleSave = (editedAdmin: AdminDetails) => {
    console.log(editedAdmin);
    if (editedAdmin._id) {
      dispatch(
        updateAdmin({ adminId: editedAdmin._id, adminData: editedAdmin })
      );
    }
    setEditPopupOpen(false);
  };
  return (
    <>
      <AdminTable onEdit={handleEdit} />

      {currentAdmin && (
        <UpdateAdminPopup
          open={editPopupOpen}
          onClose={() => setEditPopupOpen(false)}
          admin={currentAdmin}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default Admin;
