import * as Yup from "yup";
interface SchoolData {
  name: string;
  username: string;
  email: string;
  phone: string;
  gatewayIp: string;
  routerIp: string;
  gatewayStatus?: boolean;
  routerStatus?: boolean;
  isOnline?: boolean;
  address: string;

  distt: string;
  state: string;
}
export const schoolDataSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your name"),
  username: Yup.string().min(2).max(25).required("Please enter your username"),
  email: Yup.string().email().required("Please enter your email"),
  phone: Yup.string()
    .min(10)
    .max(25)
    .required("Please enter a valid phone number"),
  gatewayIp: Yup.string().required("Please enter your gatewayIp"),
  routerIp: Yup.string().required("Please enter your routerIp"),
  gatewayStatus: Yup.boolean(),
  routerStatus: Yup.boolean(),
  isOnline: Yup.boolean(),
  address: Yup.string().required("Address is required"),
  distt: Yup.string().required("District is required"),
  state: Yup.string().required("region is required"),
});
export const adminDataSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your name"),
  email: Yup.string().email().required("Please enter your email"),
  phone: Yup.string()
    .min(10)
    .max(25)
    .required("Please enter a valid phone number"),
  role: Yup.string().required("Select your role"),
  address: Yup.string().required("Address is required"),
  distt: Yup.string().required("District is required"),
  state: Yup.string().required("region is required"),
});
