import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import ghanaRegions from "../../data/ghanaRegions";
import { resetSuccessFlag } from "../../features/school/schoolSlice";
import { AdminFormData } from "../../features/admin/types";
import { createAdmin, setLoading } from "../../features/admin/adminSlice";
import { adminDataSchema } from "../../schemas/index";
import * as Yup from "yup";
import { toast } from "react-toastify";

interface CreateAdminPopupProps {
  isOpen: boolean;
  onClose: () => void;
}
interface Region {
  name: string;
  districts: string[];
}

// let stateOption: Region[] = [];
// let districtOption: string[] = [];

const CreateAdminPopup: React.FC<CreateAdminPopupProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.auth.profile);

  const [adminData, setAdminData] = useState<AdminFormData>({
    name: "",
    email: "",
    phone: "",
    address: "",
    distt: "",
    state: "",
    role: "",
  });
  const loading = useSelector((state: RootState) => state.school.loading);
  const success = useSelector((state: RootState) => state.school.success);
  const [districtOptions, setDistrictOptions] = useState<string[]>([]);
  const [stateOptions, setStateOptions] = useState<Region[]>(ghanaRegions);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const successMessage = useSelector(
    (state: RootState) => state.admin.successMessage
  );
  const errorMessage = useSelector(
    (state: RootState) => state.admin.errorMessage
  );

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    console.log(userData?.role)
    if (userData?.role === "region") {
      const userRegion = ghanaRegions.find(
        (region) => region.name === userData.state
      );
      if (userRegion) {
        setDistrictOptions(userRegion.districts);
      }
    } else if (userData?.role === "distt") {
      setAdminData((prevData) => ({
        ...prevData,
        state: userData.state,
        distt: userData.distt,
      }));
    } else if (userData?.role === "national") {
      setDistrictOptions([]);
    }
  }, [userData]);

  useEffect(() => {
    if (success) {
      setAdminData({
        name: "",
        email: "",
        phone: "",
        address: "",
        distt: "",
        state: "",
        role: "",
      });
      dispatch(resetSuccessFlag());
      onClose();
    }
  }, [success, dispatch, onClose]);
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [successMessage, errorMessage]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setValidationErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
    setAdminData((prevData) => ({ ...prevData, [id]: value }));

    if (id === "state") {
      const selectedRegion = ghanaRegions.find(
        (region) => region.name === value
      );
      setDistrictOptions(selectedRegion ? selectedRegion.districts : []);
      setAdminData((prevData) => ({ ...prevData, distt: "" }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(setLoading(true)); // Action to set loading state

    try {
      console.log(`adminData before validation: `, adminData);

      await adminDataSchema.validate(adminData, { abortEarly: false });
      console.log(`adminData after validation: `, adminData);

      dispatch(createAdmin(adminData));
    } catch (errors) {
      console.error("Error during validation or dispatch: ", errors);
      if (errors instanceof Yup.ValidationError) {
        const newValidationErrors: Record<string, string> = {};
        errors.inner.forEach((error) => {
          newValidationErrors[error.path as string] = error.message;
        });
        setValidationErrors(newValidationErrors);
      } else {
        console.error("An unexpected error occurred: ", errors);
      }
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        className=" important shadow-xl w-full 2xl:text-25px"
        footer={null}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <form onSubmit={handleSubmit} className=" xl:w-[1100px] lg:w-[900px]">
          <h1 className="lg:text-[18px] font-semibold mb-10">
            Create New Admin
          </h1>
          <div className="grid grid-cols-2 gap-6 2xl:gap-10  ">
            <div>
              <label className="lg:text-[14px] font-medium">
                {" "}
                Name <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full my-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="name"
                value={adminData.name}
                onChange={handleChange}
              />
              {validationErrors.name && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.name}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold">
                {" "}
                Address <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full  py-2 my-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="address"
                value={adminData.address}
                onChange={handleChange}
              />
              {validationErrors.address && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.address}
                </div>
              )}
              {userData?.role === "national" && (
                <div>
                  <label className="lg:text-[14px] font-semibold">
                    Regions <span className="text-pink-800">*</span>
                  </label>
                  <select
                    className="outline-none rounded-lg w-full my-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] xl:h-[50px]"
                    id="state"
                    value={adminData.state}
                    onChange={handleChange}
                  >
                    <option value=""> Select new state</option>
                    {stateOptions.map((region) => (
                      <option key={region.name} value={region.name}>
                        {region.name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.state && (
                    <div className="text-red-500 text-[12px]">
                      {validationErrors.state}
                    </div>
                  )}
                </div>
              )}

              <label className="lg:text-[14px] font-semibold">
                Role <span className="text-pink-800">*</span>
              </label>
              <select
                className="outline-none rounded-lg w-full py-2 my-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] xl:h-[50px]"
                id="role"
                value={adminData.role}
                onChange={handleChange}
              >
                <option>Select Role</option>
                {userData?.role === "national" && (
                  <option key={"national"} value={"national"}>
                    National Admin
                  </option>
                )}
                {userData?.role === "national" && (
                  <option key={"region"} value={"region"}>
                    Regional Admin
                  </option>
                )}
                {userData?.role === "national" && (
                  <option key={"distt"} value={"distt"}>
                    District Admin
                  </option>
                )}
                {userData?.role === "region" && (
                  <option value={"region"}>Regional Admin</option>
                )}
                {userData?.role === "region" && (
                  <option value={"distt"}>District Admin</option>
                )}
                {userData?.role === "distt" && (
                  <option key={"national"} value={"distt"}>
                    District Admin
                  </option>
                )}
              </select>
              {validationErrors.role && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.role}
                </div>
              )}
            </div>
            <div>
              <label className="lg:text-[14px] font-semibold">
                {" "}
                Email <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full my-2 py-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="email"
                value={adminData.email}
                onChange={handleChange}
              />
              {validationErrors.email && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.email}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold">
                {" "}
                Phone <span className="text-pink-800">*</span>
              </label>
              <p className="text-[12px] lg:text-[15px] text-gray-500 ">
                {" "}
                {/* Should be a valid phone numbers */}
              </p>

              <input
                className=" outline-none  rounded-lg w-full  my-2 py-2 lg:text-[14px] bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="phone"
                value={adminData.phone}
                onChange={handleChange}
              />
              {validationErrors.phone && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.phone}
                </div>
              )}
              {(userData?.role === "national" ||
                userData?.role === "region") && (
                <div>
                  <label className="lg:text-[14px] font-semibold  ">
                    District <span className="text-pink-800">*</span>
                  </label>
                  <select
                    className="outline-none rounded-lg  my-2   w-full py-2 bg-white border lg:text-[14px] border-gray-300 pl-3 inline-block box-border h-[35px] xl:h-[50px]"
                    id="distt"
                    value={adminData.distt}
                    onChange={handleChange}
                  >
                    <option value=""> Select your district</option>
                    {districtOptions.map((district, index) => (
                      <option key={index} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                  {validationErrors.distt && (
                    <div className="text-red-500 text-[12px]">
                      {validationErrors.distt}
                    </div>
                  )}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="rounded-lg bg-black lg:text-[15px] text-[15px] text-white mt-8 w-[130px] px-3 py-3"
            >
              {loading ? "Creating..." : "Create"}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CreateAdminPopup;
