import axios from "axios";
import {
  SchoolApiResponse,
  SchoolData,
  SchoolDetails,
} from "../features/school/types";
import { Credentials } from "../features/admin/types";
import { PUBLIC_URL } from "../constants/constant";

export const login = async (credentials: Credentials) => {
  try {
    const response = await axios.post(
      `${PUBLIC_URL}/api/v1/admin/login`,
      credentials
    );
    console.log(response.data)
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const fetchUserProfile = async (token: string) => {
  try {
    const response = await axios.get(`${PUBLIC_URL}/api/v1/admin/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const addSchool = async (
  schoolData: SchoolData,
  token: string
): Promise<SchoolApiResponse> => {
  try {
    console.log(schoolData);

    const response = await axios.post(
      `${PUBLIC_URL}/api/v1/auth/create/school`,
      schoolData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(`response from addSchool response ${response.data.name}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchSchoolsApi = async (token: string) => {
  console.log(token);
  try {
    const response = await axios.get(`${PUBLIC_URL}/api/v1/all/schools`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSchoolApi = async (
  schoolId: string,
  schoolData: SchoolDetails,
  token: string
) => {
  console.log(`schoolData from ${schoolData.gatewayIp}`);
  const response = await axios.put(
    `${PUBLIC_URL}/api/v1/update/school/${schoolId}`,
    schoolData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export const deleteSchoolApi = async (schoolId: any, token: string) => {
  try {
    const response = await axios.delete(
      `${PUBLIC_URL}/api/v1/delete/school/${schoolId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const forgotPasswordApi = async (email: string) => {
  console.log(`email from forgotpassword api ${email}`);
  try {
    const response = await axios.post(
      `${PUBLIC_URL}/api/v1/school/forgot-password`,
      {
        email: email,
      }
    );
    console.log(response.data);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const resetPasswordApi = async (token: string, newPassword: string) => {
  try {
    const response = await axios.post(
      `${PUBLIC_URL}/api/v1/school/reset-password`,
      {
        token,
        newPassword,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchICMPPingHistoryApi = async (
  schoolId: string,
  timeFrom: number,
  timeTill: number,
  token: string
) => {
  try {
    const response = await axios.get(
      `${PUBLIC_URL}/api/v1/icmp-history/${schoolId}/${timeFrom}/${timeTill}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
