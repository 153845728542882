import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import { fetchICMPPingHistory } from "../../features/school/schoolSlice";
import { AppDispatch, RootState } from "../../store";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Graph = () => {
  const dispatch: AppDispatch = useDispatch();
  const { schoolId } = useParams<{ schoolId: string }>();
  const { gatewayHistory, routerHistory, loading } = useSelector(
    (state: RootState) => state.school
  );
  const reversedGatewayHistory = gatewayHistory
    ? [...gatewayHistory].slice().reverse()
    : [];
  const reversedRouterHistory = routerHistory
    ? [...routerHistory].slice().reverse()
    : [];
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment().subtract(1, "days").toDate(),
    moment().toDate(),
  ]);

  useEffect(() => {
    if (schoolId && dateRange[0] && dateRange[1]) {
      const timeFrom = moment(dateRange[0]).unix();
      const timeTill = moment(dateRange[1]).unix();
      dispatch(fetchICMPPingHistory({ schoolId, timeFrom, timeTill }));
    }
  }, [dispatch, schoolId, dateRange]);

  const chartData = {
    labels: reversedGatewayHistory.map((entry) =>
      new Date(parseInt(entry.clock) * 1000).toLocaleString()
    ),

    datasets: [
      {
        label: "Gateway Ping Response Time",
        data: reversedGatewayHistory.map((entry) => parseInt(entry.value)),

        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
      {
        label: "Router Ping Response Time",
        data: reversedRouterHistory.map((entry) => parseInt(entry.value)),

        fill: false,
        borderColor: "rgb(192, 75, 75)",
        tension: 0.1,
      },
    ],
  };

  const handleDateChange = (range: [Date, Date] | null) => {
    if (range) {
      setDateRange([range[0], range[1]]);
    }
  };

  return (
    <div className="space-y-6 mb-6 flex flex-col">
      <DateRangePicker
        // format="yyyy-mm-dd"
        value={dateRange}
        onChange={handleDateChange}
        placeholder="Select Date Range"
      />
      {loading ? (
        <div className="justify-center align-middle mx-auto mt-8">
          <CircularProgress />
        </div>
      ) : (
        <Line data={chartData} />
      )}
    </div>
  );
};

export default Graph;
