import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AdminData, AdminDetails } from "./types";
import { RootState } from "../../store";

import {
  addAdmin,
  deleteAdminApi,
  fetchAdminsApi,
  forgotPasswordApi,
  resetPasswordApi,
  updateAdminApi,
} from "../../api/adminAPI";

export interface AdminState {
  admins: AdminDetails[];
  loading: boolean;
  error: string | null;
  success: boolean | null;
  successMessage: string | null;
  errorMessage: string | null;
}
const initialState: AdminState = {
  admins: [],
  loading: false,
  error: null,
  success: null,
  successMessage: "",
  errorMessage: "",
};

interface UpdateAdminPayload {
  adminId: string;
  adminData: AdminData;
}

export const createAdmin = createAsyncThunk(
  "admin/createAdmin",
  async (adminData: AdminData, { getState, rejectWithValue }) => {
    const token = (getState() as RootState).auth.token;
    console.log(`adminData ${adminData}`);
    if (!token) {
      return rejectWithValue("Auth token is require");
    }
    try {
      const response = await addAdmin(adminData, token);
      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Error adding Admin"
      );
    }
  }
);
export const fetchAdmins = createAsyncThunk(
  "admins/fetchAdmins",
  async (_, { getState, rejectWithValue }) => {
    const token = (getState() as RootState).auth.token;
    if (!token) {
      return rejectWithValue("Auth token is required");
    }
    try {
      const data = await fetchAdminsApi(token);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "Error fetching Admins");
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "admin/updateAdmin",
  async (
    { adminId, adminData }: UpdateAdminPayload,
    { getState, rejectWithValue }
  ) => {
    try {
      const token = (getState() as RootState).auth.token;
      if (!token) {
        throw new Error("Auth token in required");
      }
      const response = await updateAdminApi(adminId, adminData, token);
      return { adminId, data: response };
    } catch (e: any) {
      return rejectWithValue(
        e.response?.data?.message || "Error Updating Admin"
      );
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  "admin/deleteAdmin",
  async (adminId: string, { getState, rejectWithValue }) => {
    try {
      const token = (getState() as RootState).auth.token;
      if (!token) {
        return rejectWithValue("Auth token is required");
      }
      await deleteAdminApi(adminId, token);
      return adminId;
    } catch (e: any) {
      return rejectWithValue(
        e.response?.data?.message || "Error Deleting Admin"
      );
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "admin/forgot-password",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await forgotPasswordApi(email);
      return response.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "admin/reset-password",
  async (data: { token: string; newPassword: string }, { rejectWithValue }) => {
    try {
      const response = await resetPasswordApi(data.token, data.newPassword);
      return response.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetMessages: (state) => {
      state.successMessage = null;
      state.errorMessage = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = "Admin created successfully";
        state.admins.push(action.payload.userDetails);
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message || "Unknown error occurred";
        state.errorMessage = action.error.message || "Error creating Admin";
      })
      .addCase(fetchAdmins.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = action.payload;
      })
      .addCase(fetchAdmins.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message || "Unknown error occured";
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.loading = false;
        // state.success = true;
        const index = state.admins.findIndex(
          (admin) => admin._id === action.payload.adminId
        );
        if (index !== -1) {
          state.admins[index] = {
            ...state.admins[index],
            ...action.payload.data,
          };
        }
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        state.admins = state.admins.filter(
          (admin) => admin._id !== action.payload
        );
        state.success = true;
      })
      .addCase(deleteAdmin.rejected, (state, action) => {
        state.error =
          action.error.message || "Unknown error occured during deletion";
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload as string;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});
export const { resetMessages, setLoading } = adminSlice.actions;

export default adminSlice.reducer;
