import React from "react";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineMore,
  AiOutlineEye,
} from "react-icons/ai";
import { AdminDetails } from "../features/admin/types";

interface CustomDropdownProps {
  admin: AdminDetails;
  isOpen: boolean;
  onToggle: () => void;
  onEdit: (admin: AdminDetails) => void;
  onDelete: (adminId: string) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  admin,
  isOpen,
  onToggle,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={onToggle}
          className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 "
        >
          <AiOutlineMore />
        </button>
      </div>

      {isOpen && (
        <div className="absolute right-0 z-10 w-36 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
          <div className="py-1">
            <button
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={() => {
                onToggle();
                onEdit(admin);
              }}
            >
              <AiOutlineEdit className="mr-3" /> Edit
            </button>
            <button
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={() => {
                onToggle();
                onDelete(admin._id!);
              }}
            >
              <AiOutlineDelete className="mr-3" /> Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
