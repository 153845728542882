import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../../features/auth/authSlice";
import schoolReducer from "../../features/school/schoolSlice";
import adminReducer from "../../features/admin/adminSlice";
const rootReducer = combineReducers({
  auth: authReducer,
  school: schoolReducer,
  admin: adminReducer,
});

export default rootReducer;
