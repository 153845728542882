import React, { useRef } from "react";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineMore,
  AiOutlineEye,
} from "react-icons/ai";
import { SchoolDetails } from "../features/school/types";
import { useOutsideAlerter } from "../hooks/useOutsideAlerter";

interface CustomDropdownSchoolProps {
  school: SchoolDetails;
  isOpen: boolean;
  onToggle: () => void;
  onEdit: (admin: SchoolDetails) => void;
  onDelete: (adminId: string) => void;
  onView: (schoolId: string) => void;
}

const CustomDropdownSchool: React.FC<CustomDropdownSchoolProps> = ({
  school,
  isOpen,
  onToggle,
  onEdit,
  onDelete,
  onView,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter<HTMLDivElement>(dropdownRef, onToggle);
  return (
    <div className="relative inline-block text-left ">
      <div>
        <button
          onClick={onToggle}
          className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 "
        >
          <AiOutlineMore />
        </button>
      </div>

      {isOpen && (
        <div className="absolute right-0 z-10 w-36 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
          <div className="py-1">
            <button
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={() => {
                onToggle();
                if (school._id) onView(school._id);
              }}
            >
              <AiOutlineEye className="mr-4" /> View
            </button>
            <button
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={() => {
                onToggle();
                onEdit(school);
              }}
            >
              <AiOutlineEdit className="mr-4" /> Edit
            </button>
            <button
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={() => {
                onToggle();
                onDelete(school._id!);
              }}
            >
              <AiOutlineDelete className="mr-4" /> Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDropdownSchool;
