import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import ghanaRegions from "../../data/ghanaRegions";
import { resetSuccessFlag } from "../../features/school/schoolSlice";
import { SchoolDetails } from "../../features/school/types";
import { schoolDataSchema } from "../../schemas/index";
import * as Yup from "yup";
interface EditSchoolPopupProps {
  open: boolean;
  onClose: () => void;
  school: SchoolDetails;
  onSave: (school: SchoolDetails) => void;
}
interface Region {
  name: string;
  districts: string[];
}

const EditSchoolPopup: React.FC<EditSchoolPopupProps> = ({
  open,
  onClose,
  school,
  onSave,
}) => {
  // const [editedSchool, setEditedSchool] = useState<SchoolDetails>(school);

  const [editedSchool, setEditedSchool] = useState<SchoolDetails>({
    gatewayId: "",
    routerId: "",
    password: "",
    createdby: undefined,
    _id: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    __v: undefined,
    name: "",
    username: "",
    email: "",
    phone: "",
    gatewayIp: "",
    routerIp: "",
    address: "",
    distt: "",
    state: "",
  });
  useEffect(() => {
    setEditedSchool(school);
    console.log("Initial school:", school); // Optional, for troubleshooting
  }, [school]);

  const dispatch: AppDispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.auth.profile);

  const loading = useSelector((state: RootState) => state.school.loading);
  const success = useSelector((state: RootState) => state.school.success);
  const [districtOptions, setDistrictOptions] = useState<string[]>([]);
  const [stateOptions, setStateOptions] = useState<Region[]>(ghanaRegions);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    if (userData?.role === "region") {
      const userRegion = ghanaRegions.find(
        (region) => region.name === userData.state
      );
      if (userRegion) {
        setDistrictOptions(userRegion.districts);
        // Set distt to the first district of the user's region if not set
        if (!editedSchool.distt) {
          setEditedSchool((prev) => ({
            ...prev,
            distt: userRegion.districts[0],
          }));
        }
      }
    } else if (userData?.role === "distt") {
      setEditedSchool((prev) => ({
        ...prev,
        state: userData.state,
        distt: userData.distt,
      }));
    } else if (userData?.role === "national") {
      setDistrictOptions([]);
    }
  }, [userData]);

  useEffect(() => {
    if (success) {
      dispatch(resetSuccessFlag());
      onClose();
    }
  }, [success, dispatch, onClose]);
  useEffect(() => {
    // Set district options based on the initial state of the school
    const initialRegion = ghanaRegions.find(
      (region) => region.name === school.state
    );
    if (initialRegion) {
      setDistrictOptions(initialRegion.districts);

      // If the initial district is not in the new options, set it to the first one
      if (!initialRegion.districts.includes(school.distt)) {
        setEditedSchool((prev) => ({
          ...prev,
          distt: initialRegion.districts[0],
        }));
      }
    }
  }, [school]);
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setEditedSchool((prev) => ({ ...prev, [id]: value }));
    setValidationErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
    if (id === "state") {
      const selectedRegion = ghanaRegions.find(
        (region) => region.name === value
      );
      if (selectedRegion) {
        setDistrictOptions(selectedRegion.districts);
        // Reset district if the state changes
        setEditedSchool((prev) => ({
          ...prev,
          distt: selectedRegion.districts[0],
        }));
      } else {
        setDistrictOptions([]);
      }
    }
  };

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   dispatch(createAdmin(editedAdmin));
  // };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await schoolDataSchema.validate(editedSchool, { abortEarly: false });
      onSave(editedSchool);
      onClose();
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        const newValidationErrors: Record<string, string> = {};
        errors.inner.forEach((error) => {
          newValidationErrors[error.path as string] = error.message;
        });
        setValidationErrors(newValidationErrors);
      } else {
        console.error("An  unexpected error occured:", errors);
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onOk={onClose}
        onCancel={onClose}
        footer={null}
        className="shadow-xl  xl:text-25px"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "75%",
        }}
      >
        <form onSubmit={handleSave} className="xl:w-[1100px] lg:text-[14px] ">
          <h1 className="lg:text-[18px] font-semibold mb-10">Update School</h1>
          <div className="grid grid-cols-2 gap-6 2xl:gap-10  ">
            <div>
              <label className="lg:text-[14px] font-semibold ">
                Name <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full my-2  bg-white border border-gray-300 pl-3   inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="name"
                value={editedSchool.name}
                onChange={handleChange}
              />
              {validationErrors.name && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.name}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold ">
                Username <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full my-2  bg-white border border-gray-300 pl-3   inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="username"
                value={editedSchool.username}
                onChange={handleChange}
              />
              {validationErrors.username && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.username}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold ">
                Gateway <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full my-2  bg-white border border-gray-300 pl-3   inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="gatewayIp"
                value={editedSchool.gatewayIp}
                onChange={handleChange}
              />
              {validationErrors.gatewayIp && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.gatewayIp}
                </div>
              )}
              {userData?.role === "national" && (
                <div>
                  <label className="lg:text-[14px] font-semibold">
                    Regions <span className="text-pink-800 ">*</span>
                  </label>
                  <select
                    className="outline-none rounded-lg w-full py-2 mt-3 mb-3 bg-white border border-gray-300 pl-3 inline-block box-border h-[35px] xl:h-[50px]"
                    id="state"
                    value={editedSchool.state}
                    onChange={handleChange}
                  >
                    <option value=""> Select your state</option>
                    {validationErrors.state && (
                      <div className="text-red-500 text-[12px]">
                        {validationErrors.state}
                      </div>
                    )}

                    {stateOptions.map((region) => (
                      <option key={region.name} value={region.name}>
                        {region.name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.regions && (
                    <div className="text-red-500 text-[12px]">
                      {validationErrors.state}
                    </div>
                  )}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold ">
                Address <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full  py-2 my-2  bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="address"
                value={editedSchool.address}
                onChange={handleChange}
              />
              {validationErrors.address && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.address}
                </div>
              )}
            </div>
            <div>
              <label className="lg:text-[14px] font-semibold">
                Email <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full my-2 py-2   bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="email"
                id="email"
                value={editedSchool.email}
                onChange={handleChange}
              />
              {validationErrors.email && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.email}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold ">
                Router <span className="text-pink-800">*</span>
              </label>
              <input
                className=" outline-none  rounded-lg w-full  py-2 my-2  bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="routerIp"
                value={editedSchool.routerIp}
                onChange={handleChange}
              />
              {validationErrors.routerIp && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.routerIp}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold">
                Phone <span className="text-pink-800">*</span>
              </label>
              <p className="text-[12px] lg:text-[12px] text-gray-500 "></p>
              <input
                className=" outline-none  rounded-lg w-full  my-2 py-2   bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="text"
                id="phone"
                value={editedSchool.phone}
                onChange={handleChange}
              />
              {validationErrors.phone && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.phone}
                </div>
              )}
              {(userData?.role === "national" ||
                userData?.role === "region") && (
                <div>
                  <label className="lg:text-[14px] font-semibold">
                    District <span className="text-pink-800">*</span>
                  </label>
                  <select
                    className="outline-none rounded-lg w-full py-2 bg-white border mt-3 mb-3 border-gray-300 pl-3 inline-block box-border h-[35px] xl:h-[50px]"
                    id="distt"
                    value={editedSchool.distt}
                    onChange={handleChange}
                  >
                    <option value=""> Select your district</option>
                    {districtOptions.map((district, index) => (
                      <option key={index} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                  {validationErrors.distt && (
                    <div className="text-red-500 text-[12px]">
                      {validationErrors.distt}
                    </div>
                  )}
                </div>
              )}
              <label className="lg:text-[14px] font-semibold">
                New Password <span className="text-pink-800">*</span>
              </label>
              <p className="text-[12px] lg:text-[12px] text-gray-500 "></p>
              <input
                className=" outline-none  rounded-lg w-full  my-2 py-2   bg-white border border-gray-300 pl-3 inline-block box-border h-[35px]  xl:h-[50px]"
                type="password"
                id="password"
                value={editedSchool.password}
                onChange={handleChange}
              />
              {validationErrors.phone && (
                <div className="text-red-500 text-[12px]">
                  {validationErrors.phone}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="rounded-lg bg-black text-[15px] xl:text-[18px] text-white mt-2 w-[130px] px-3 py-3"
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditSchoolPopup;
