type Region = {
  name: string;
  districts: string[];
};

const ghanaRegions: Region[] = [
  {
    name: "Greater Accra",
    districts: [
      "Accra Metropolitan",
      "Tema Metropolitan",
      "Ledzokuku-Krowor",
      "La Dade Kotopon",
      "La Nkwantanang Madina",
      "Adentan",
      "Ashaiman",
      "Ga East",
      "Ga West",
      "Ga South",
      "Ga Central",
      "Ablekuma North",
      "Ablekuma West",
      "Ablekuma Central",
      "Ayawaso East",
      "Ayawaso North",
      "Ayawaso West",
      "Ayawaso Central",
      "Okaikwei North",
      "Korle Klottey",
      "Kpone Katamanso",
      "Shai Osudoku",
      "Ningo Prampram",
    ],
  },
  {
    name: "Ashanti",
    districts: [
      "Kumasi Metropolitan",
      "Asokwa",
      "Bantama",
      "Oforikrom",
      "Suame",
      "Manhyia North",
      "Manhyia South",
      "Subin",
      "Nhyiaeso",
      "Kwadaso",
      "Tafo Pankrono",
      "Mampong Municipal",
      "Ejisu",
      "Juaben",
      "Effiduase",
      "Asokore",
      "Afigya-Kwabre",
      "Afigya-Kwabre North",
      "Afigya-Kwabre South",
      "Ahafo Ano North",
      "Ahafo Ano South",
      "Amansie Central",
      "Amansie West",
      "Asante Akim Central Municipal",
      "Asante Akim North",
      "Asante Akim South",
      "Atwima Kwanwoma",
      "Atwima Mponua",
      "Atwima Nwabiagya",
      "Bosome Freho",
      "Bosomtwe",
      "Obuasi Municipal",
      "Sekyere Afram Plains",
      "Sekyere Central",
      "Sekyere East",
      "Sekyere Kumawu",
      "Sekyere South",
    ],
  },
  {
    name: "Volta",
    districts: [
      "Ho Municipal",
      "Adaklu",
      "Afadjato South",
      "Agotime Ziope",
      "Akatsi North",
      "Akatsi South",
      "Anloga",
      "Central Tongu",
      "Ho West",
      "Hohoe",
      "Keta Municipal",
      "Ketu North",
      "Ketu South",
      "Kpando Municipal",
      "North Dayi",
      "North Tongu",
      "South Dayi",
      "South Tongu",
    ],
  },
  {
    name: "Eastern",
    districts: [
      "New Juaben Municipal",
      "Akuapim North",
      "Akuapim South",
      "Akuapem Central",
      "Akwatia",
      "Akyemansa",
      "Asuogyaman",
      "Atiwa",
      "Ayensuano",
      "Birim Central",
      "Birim North",
      "Birim South",
      "Denkyembour",
      "Fanteakwa",
      "Kwaebibirem",
      "Kwahu Afram Plains North",
      "Kwahu Afram Plains South",
      "Kwahu East",
      "Kwahu South",
      "Kwahu West",
      "Lower Manya Krobo",
      "Nsawam Adoagyire",
      "Suhum",
      "Upper Manya Krobo",
      "Upper West Akim",
      "West Akim",
      "Yilo Krobo",
    ],
  },
  {
    name: "Northern",
    districts: [
      "Tamale Metropolitan",
      "Gushegu",
      "Karaga",
      "Kpandai",
      "Kumbungu",
      "Mion",
      "Nanton",
      "Nanumba North",
      "Nanumba South",
      "Sagnarigu",
      "Savelugu",
      "Tatale Sangule",
      "Tolon",
      "Yendi Municipal",
      "Zabzugu",
    ],
  },
  {
    name: "Western",
    districts: [
      "Sekondi Takoradi Metropolitan",
      "Ahanta West",
      "Amenfi Central",
      "Amenfi East",
      "Amenfi West",
      "Aowin",
      "Bia East",
      "Bia West",
      "Bibiani-Anhwiaso-Bekwai",
      "Ellembelle",
      "Jomoro",
      "Juaboso",
      "Mpohor",
      "Nzema East",
      "Prestea-Huni Valley",
      "Sefwi Akontombra",
      "Sefwi Wiawso",
      "Shama",
      "Suaman",
      "Tarkwa Nsuaem Municipal",
      "Wasa Amenfi East",
      "Wassa East",
    ],
  },
  {
    name: "Central",
    districts: [
      "Cape Coast Metropolitan",
      "Abura-Asebu-Kwamankese",
      "Agona East",
      "Agona West Municipal",
      "Ajumako Enyan Essiam",
      "Asikuma Odoben Brakwa",
      "Assin North Municipal",
      "Assin South",
      "Awutu Senya",
      "Awutu Senya East",
      "Effutu Municipal",
      "Ekumfi",
      "Gomoa East",
      "Gomoa West",
      "Komenda Edina Eguafo Abirem",
      "Mfantsiman",
      "Twifo-Ati Morkwa",
      "Twifo-Hemang-Lower Denkyira",
      "Upper Denkyira East",
      "Upper Denkyira West",
    ],
  },
  {
    name: "Upper East",
    districts: [
      "Bolgatanga Municipal",
      "Bawku Municipal",
      "Binduri",
      "Bongo",
      "Builsa North",
      "Builsa South",
      "Garu",
      "Kassena Nankana East",
      "Kassena Nankana West",
      "Nabdam",
      "Pusiga",
      "Talensi",
      "Tempane",
    ],
  },
  {
    name: "Upper West",
    districts: [
      "Wa Municipal",
      "Daffiama Bussie Issa",
      "Jirapa",
      "Lambussie Karni",
      "Lawra",
      "Nadowli",
      "Nandom",
      "Sissala East",
      "Sissala West",
      "Wa East",
      "Wa West",
    ],
  },
  {
    name: "Brong-Ahafo",
    districts: [
      "Sunyani Municipal",
      "Asunafo North",
      "Asunafo South",
      "Asutifi North",
      "Asutifi South",
      "Banda",
      "Berekum Municipal",
      "Dormaa Central",
      "Dormaa East",
      "Dormaa West",
      "Jaman North",
      "Jaman South",
      "Kintampo North",
      "Kintampo South",
      "Nkoranza North",
      "Nkoranza South",
      "Pru",
      "Sene East",
      "Sene West",
      "Sunyani West",
      "Tain",
      "Tano North",
      "Tano South",
      "Techiman Municipal",
      "Techiman North",
      "Wenchi Municipal",
    ],
  },
  {
    name: "Western North",
    districts: [
      "Bibiani-Anhwiaso-Bekwai",
      "Bodi",
      "Juaboso",
      "Sefwi Akontombra",
      "Sefwi Wiawso",
      "Suaman",
    ],
  },
  {
    name: "Ahafo",
    districts: [
      "Asunafo North",
      "Asunafo South",
      "Asutifi North",
      "Asutifi South",
      "Tano North",
      "Tano South",
    ],
  },
  {
    name: "Bono East",
    districts: [
      "Atebubu-Amantin",
      "Kintampo North",
      "Kintampo South",
      "Nkoranza North",
      "Nkoranza South",
      "Pru East",
      "Pru West",
      "Sene East",
      "Sene West",
      "Techiman North",
      "Techiman South",
    ],
  },
  {
    name: "Oti",
    districts: [
      "Biakoye",
      "Jasikan",
      "Kadjebi",
      "Krachi East",
      "Krachi Nchumuru",
      "Krachi West",
      "Nkwanta North",
      "Nkwanta South",
    ],
  },
  {
    name: "Savannah",
    districts: [
      "Bole",
      "Central Gonja",
      "East Gonja",
      "North Gonja",
      "Sawla-Tuna-Kalba",
      "West Gonja",
    ],
  },
  {
    name: "North East",
    districts: [
      "Bunkpurugu-Nyankpanduri",
      "Chereponi",
      "East Mamprusi",
      "Mamprugu Moagduri",
      "West Mamprusi",
      "Yunyoo-Nasuan",
    ],
  },
];

export default ghanaRegions;
