import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../store";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import "../../../src/index.css";
import { AdminDetails } from "../../features/admin/types";
import { deleteAdmin } from "../../features/admin/adminSlice";
import CustomDropdown from "../CustomDropdown";

interface AdminTableProps {
  onEdit: (admin: AdminDetails) => void;
}

const AdminTable: React.FC<AdminTableProps> = ({ onEdit }) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState("");
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  const handleConfirmDelete = () => {
    if (selectedAdminId) {
      dispatch(deleteAdmin(selectedAdminId));
    }
    setConfirmDialogOpen(false);
  };

  const admins = useSelector((state: RootState) => state.admin.admins);
  const dispatch = useDispatch<AppDispatch>();
  const handleToggleDropdown = (adminId: string) => {
    setOpenDropdownId(openDropdownId === adminId ? null : adminId);
  };
  const columns = [
    {
      name: "name",
      label: "Admin Name",
    },
    { name: "email", label: "Email" },
    { name: "phone", label: "Phone" },
    { name: "address", label: "Address" },
    { name: "distt", label: "District" },
    { name: "state", label: "State" },

    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: any) => {
          const admin = admins[dataIndex];

          return (
            <>
              {/* <IconButton onClick={() => handleView(admin)}>
                <VisibilityIcon />
              </IconButton> */}

              <CustomDropdown
                admin={admin}
                isOpen={openDropdownId === admin?._id}
                onToggle={() => handleToggleDropdown(admin?._id!)}
                onEdit={() => onEdit(admin)}
                onDelete={() => handleDelete(admin?._id!)}
              />
            </>
          );
        },
        classes: {
          root: "table-container",
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox" as const,
    responsive: "standard" as const,
    download: true,
    print: true,
    selectableRows: "none" as const,
    elevation: 0,
  };

  const handleDelete = (adminId: string) => {
    setSelectedAdminId(adminId);
    setConfirmDialogOpen(true);
  };

  return (
    <>
      <MUIDataTable
        title={"Admin List"}
        data={admins}
        columns={columns}
        options={options}
      />
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete this Admin?"
      />
    </>
  );
};

export default AdminTable;
