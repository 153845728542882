import React, { useEffect, useRef, useState } from "react";
import { styled, useTheme, Theme } from "@mui/material/styles";
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  IconButton,
  Typography,
  Drawer as MuiDrawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  CSSObject,
  Box,
  CssBaseline,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import PeopleIcon from "@mui/icons-material/People";
import { Routes, Route, Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import ProtectedRoute from "../../components/ProtectedRoute";
import SchoolsComponent from "../School/School";
import Admin from "../Admin/Admin";
import Popup from "../Popup";
import { fetchSchools } from "../../features/school/schoolSlice";
import { fetchAdmins } from "../../features/admin/adminSlice";
import CreateAdminPopup from "../Admin/CreateAdminPopup";
import { IoIosLogOut } from "react-icons/io";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import { logout } from "../../features/auth/authSlice";
import Graph from "../School/Graph";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface DrawerProps {
  open?: boolean;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<DrawerProps>(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Dashboard = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isCreateSchoolModalOpen, setIsCreateSchoolModalOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDropDown = () => {
    setShowDropDown((prevShowDropDown) => !prevShowDropDown);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchSchools());
    dispatch(fetchAdmins());
  }, [dispatch]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter<HTMLDivElement>(dropdownRef, handleDropDown);
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <Box sx={{ display: "flex " }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <div className="">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <div className="w-full flex justify-between relative  ">
                <Typography variant="h6" noWrap>
                  Dashboard
                </Typography>
                <img
                  src="assets/images/profile.png"
                  className=""
                  onClick={handleDropDown}
                  alt="user profile"
                />

                {showDropDown && (
                  <div
                    ref={dropdownRef}
                    className="absolute right-0 z-10 w-32 mt-11 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                  >
                    <div className="py-1">
                      {/* <button
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        onClick={() => {}}
                      >
                        <AiOutlineEdit className="mr-3" /> Profile
                      </button> */}
                      <button
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        onClick={handleLogout}
                      >
                        <IoIosLogOut className="mr-3" /> Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Toolbar>
          </div>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {/* <ListItemButton component={Link} to="/">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton> */}
            <ListItemButton component={Link} to="/">
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Schools" />
            </ListItemButton>
            <ListItemButton component={Link} to="/admin">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Admins" />
            </ListItemButton>
            {/* Add more ListItemButtons for other routes */}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Routes>
            <Route
              path="/schools/:schoolId/graph"
              element={
                <ProtectedRoute>
                  <Graph />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/"
              element={
                <ProtectedRoute>
                  <div>Dashboard Home</div>
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <div className="mx-8">
                    <p className="text-[24px] text-gray-600 underline-offset-auto">
                      / Schools
                    </p>
                    <button
                      className="py-3 px-3 bg-black text-white rounded-[6px] text-md my-10"
                      onClick={() => setIsCreateSchoolModalOpen(true)}
                    >
                      Create New School
                    </button>
                    <Popup
                      isOpen={isCreateSchoolModalOpen}
                      onClose={() => setIsCreateSchoolModalOpen(false)}
                    />
                    <SchoolsComponent />
                  </div>
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <div className="mx-8">
                    <p className="text-[24px] text-gray-600 underline-offset-auto">
                      / Admins
                    </p>
                    <button
                      className="py-3 px-3 bg-black text-white rounded-[6px] text-md my-10 "
                      onClick={() => setIsCreateSchoolModalOpen(true)}
                    >
                      Create New Admin
                    </button>
                    <CreateAdminPopup
                      isOpen={isCreateSchoolModalOpen}
                      onClose={() => setIsCreateSchoolModalOpen(false)}
                    />
                    <Admin />
                  </div>
                </ProtectedRoute>
              }
            />
            {/* Add other routes as needed */}
          </Routes>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
