import axios from "axios";
import {
  AdminApiResponse,
  AdminData,
  AdminDetails,
} from "../features/admin/types";
import { PUBLIC_URL } from "../constants/constant";

export const addAdmin = async (
  adminData: AdminData,
  token: string
): Promise<AdminApiResponse> => {
  console.log(adminData);
  try {
    const response = await axios.post(
      `${PUBLIC_URL}/api/v1/admin/create`,
      adminData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchAdminsApi = async (token: string) => {
  try {
    const response = await axios.get(`${PUBLIC_URL}/api/v1/admin/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
};
export const updateAdminApi = async (
  adminId: string,
  adminData: AdminDetails,
  token: string
) => {
  const response = await axios.put(
    `${PUBLIC_URL}/api/v1/update/admin/${adminId}`,
    adminData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};
export const deleteAdminApi = async (adminId: string, token: string) => {
  const response = await axios.delete(
    `${PUBLIC_URL}/api/v1/delete/admin/${adminId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const forgotPasswordApi = async (email: string) => {
  console.log(`email from forgotpassword api ${email}`);
  try {
    const response = await axios.post(
      `${PUBLIC_URL}/api/v1/admin/forgot-password`,
      {
        email: email,
      }
    );
    console.log(response.data);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};
export const resetPasswordApi = async (token: string, newPassword: string) => {
  try {
    const response = await axios.post(
      `${PUBLIC_URL}/api/v1/admin/reset-password`,
      {
        token,
        newPassword,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
