import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchProfile, loginUser } from "../../features/auth/authSlice";
import { Credentials } from "../../features/admin/types";
import { forgotPassword, setLoading } from "../../features/admin/adminSlice";
import Password from "antd/es/input/Password";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";

const Login = () => {
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [credentials, setCredentials] = useState<Credentials>({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const authState = useSelector((state: RootState) => state.auth);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  // const { loading, error, success, successMessage } = useSelector(
  //   (state: RootState) => state.admin
  // );

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const loadingState = useSelector((state: RootState) => state.auth.loading);
  const adminState = useSelector((state: RootState) => state.admin);
  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!credentials.email.trim()) {
      setEmailError("Email cannot be empty");
    } else {
      setEmailError(null);
    }
    if (!credentials.password.trim()) {
      setPasswordError("Password cannot be empty");
    } else {
      setPasswordError(null);
    }
    if (credentials.email && credentials.password) {
      try {
        await dispatch(loginUser(credentials));
        if (adminState.success === true) {
          toast.success("Login successful!");
        }
      } catch (error: any) {
        setLoginError(
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
            "Network error"
        );
      }
    }
  };

  const handleForgetPasswordSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    if (!credentials.email) {
      setPasswordError("password cannot be empty");
    } else {
      try {
        dispatch(forgotPassword(credentials.email));

        // console.log(
        //   "Backend request will be made with email:",
        //   credentials.email
        // );
      } catch (error) {
        console.error("Backend request failed:", error);
      }
    }

    if (adminState.success === true) {
      setIsForgetPassword(false);
      dispatch(setLoading(true)); // Action to set loading state
    }
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setCredentials((prevData) => ({ ...prevData, [id]: value }));
  };
  useEffect(() => {
    if (authState.status === "failed") {
      // Display an error toast when the login attempt fails
      toast.error(authState.error);
    }
  }, [authState]);
  useEffect(() => {
    if (adminState.success === true) {
      setIsForgetPassword(false);
    }
  }, [adminState]);
  useEffect(() => {
    if (authState.token) {
      dispatch(fetchProfile());
      navigate("/");
    }
  }, [authState.token, dispatch, navigate]);

  useEffect(() => {
    if (adminState.error) {
      toast.error(adminState.errorMessage);
    }
    if (adminState.success === true) {
      toast.success("Password reset email sent successfully!");
    }
  }, [adminState]);
  return (
    <div className="relative flex md:items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
      <div className="max-w-[1320px] w-[100%] mx-auto px-4 z-1">
        <div className="flex flex-wrap -mx-3 justify-around">
          <div className="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-4/12 lg:w-4/12 xl:w-3/12">
            <div className="absolute top-0 left-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 md:flex">
              <div className="relative flex flex-col justify-center h-full bg-cover px-24 m-4 overflow-hidden bg-[url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg')] rounded-xl ">
                <span className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-blue-500 to-violet-500 opacity-60"></span>
                <h4 className="z-20 mt-12 font-bold text-2xl text-white">
                  "Mistakes are stepping stones to learning"
                </h4>
                <p className="z-20 mt-2 text-white ">
                  Persistence acts like a key, unlocking potential in
                  challenges, similar to turning stones into stepping stones.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-5/12 lg:w-5/12 xl:w-4/12">
            {isForgetPassword ? (
              <div className="relative top-0 right-0 flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 rounded-2xl bg-clip-border">
                <div className="p-6 pb-0 mb-0">
                  <h4 className="font-bold text-2xl text-[#344767]">
                    Forgot Password
                  </h4>
                  <p className="mb-0 mt-2 text-[#344767c2]">
                    Enter your email to receive reset link
                  </p>
                </div>
                <div className="flex-auto p-6">
                  <form onSubmit={handleForgetPasswordSubmit}>
                    <div className="mb-4">
                      <input
                        type="email"
                        id="email"
                        placeholder="Email"
                        value={credentials.email}
                        onChange={(e) => {
                          setPasswordError("");
                          handleChange(e);
                        }}
                        className="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                      />
                      {passwordError && (
                        <p className="text-red-500 text-[15px] mt-3">
                          {passwordError}
                        </p>
                      )}
                      <div
                        data-lastpass-icon-root="true"
                        style={{
                          position: "relative",
                          height: "0px",
                          width: "0px",
                          float: "left",
                        }}
                      ></div>{" "}
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="inline-block w-full px-16 py-3.5 mt-4 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-violet-500 border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25"
                        disabled={adminState.loading}
                      >
                        {adminState.loading === true ? (
                          <CircularProgress
                            size={24}
                            style={{ color: "white" }}
                          />
                        ) : (
                          "Send reset link"
                        )}
                      </button>
                    </div>
                    <div
                      onClick={() => setIsForgetPassword(false)}
                      className="text-center mt-4 text-[#4c9fe2] cursor-pointer"
                    >
                      Remember your password?
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div className="relative top-0 right-0 flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 rounded-2xl bg-clip-border">
                <div className="p-6 pb-0 mb-0">
                  <h4 className="font-bold text-2xl text-[#344767]">Sign In</h4>
                  <p className="mb-0 mt-2 text-[#344767c2]">
                    Enter your email and password to sign in
                  </p>
                </div>
                <div className="flex-auto p-6">
                  <form onSubmit={handleLoginSubmit}>
                    <div className="mb-4">
                      <input
                        type="email"
                        id="email"
                        placeholder="Email"
                        value={credentials.email}
                        onChange={(e) => {
                          setEmailError("");
                          handleChange(e);
                        }}
                        className="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                      />
                      {emailError && (
                        <p className="text-red-500 text-[12px] mt-1">
                          {emailError}
                        </p>
                      )}
                      <div
                        data-lastpass-icon-root="true"
                        style={{
                          position: "relative",
                          height: "0px",
                          width: "0px",
                          float: "left",
                        }}
                      ></div>{" "}
                    </div>
                    <div className="mb-4">
                      <input
                        type="password"
                        id="password"
                        value={credentials.password}
                        onChange={(e) => {
                          setPasswordError("");
                          handleChange(e);
                        }}
                        placeholder="Password"
                        className="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                      />
                      {passwordError && (
                        <p className="text-red-500 text-[12px] mt-1">
                          {passwordError}
                        </p>
                      )}
                      <div
                        data-lastpass-icon-root="true"
                        style={{
                          position: "relative",
                          height: "0px",
                          width: "0px",
                          float: "left",
                        }}
                      ></div>{" "}
                    </div>
                    <div
                      onClick={() => setIsForgetPassword(true)}
                      className="float-right text-[#4c9fe2] cursor-pointer"
                    >
                      Forgot password?
                    </div>
                    <div className="text-center">
                      <div className="text-center">
                        <button
                          type="submit"
                          disabled={loadingState}
                          className={`inline-block w-full px-16 py-3.5 mt-6 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-violet-500 border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25 ${
                            loadingState ? "opacity-50" : ""
                          }`}
                        >
                          {loadingState ? (
                            <CircularProgress
                              size={24}
                              style={{ color: "white" }}
                            />
                          ) : (
                            "Sign in"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                  {loginError && (
                    <div className="error-message text-black text-2xl ">
                      {loginError}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
