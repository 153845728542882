import React, { useState } from "react";
import { useDispatch } from "react-redux";
import EditSchoolPopup from "./EditSchoolPopup";
import { SchoolDetails } from "../../features/school/types";
import { updateSchool } from "../../features/school/schoolSlice";
import SchoolsTable from "../../components/Tables/SchoolTable";
import { AppDispatch } from "../../store";

const SchoolsComponent: React.FC = () => {
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [currentSchool, setCurrentSchool] = useState<SchoolDetails | null>(
    null
  );

  const dispatch = useDispatch<AppDispatch>();

  const handleEdit = (school: any) => {
    setCurrentSchool(school);
    setEditPopupOpen(true);
  };

  const handleSave = (editedSchool: SchoolDetails) => {
    if (editedSchool._id) {
      dispatch(
        updateSchool({ schoolId: editedSchool._id, schoolData: editedSchool })
      );
    }
    setEditPopupOpen(false);
  };

  return (
    <>
      <SchoolsTable onEdit={handleEdit} />
      {currentSchool && (
        <EditSchoolPopup
          open={editPopupOpen}
          onClose={() => setEditPopupOpen(false)}
          school={currentSchool}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default SchoolsComponent;
